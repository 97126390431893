@mixin revert-whitetable() {
  font-size: 12px !important;
  margin: 0 !important;
}

.ev-date-picker {
  width: 240px;

  tr {
    th {
      @include revert-whitetable();
      padding: 0 !important;

      .btn-sm {
        @include revert-whitetable();
        padding: 4px 7px !important;
      }
    }
    td {
      @include revert-whitetable();
      padding: 0 !important;

      .btn-sm {
        @include revert-whitetable();
        padding: 4px 7px !important;
      }
    }
  }
}

.ev-date-picker-menu-placement {
  padding: 0;
  width: 240px;
}

.ev-date-picker-title {
  width: 100%;
}

.ev-date-picker-table {
  width: 100%;
  background-color: white;
}

.ev-date-picker-table-icon {
  width: 28px;
}

.ev-date-picker-view {
  .ev-day {
    .btn[disabled] {
      border: 0 !important;
    }
    .btn-fixed {
      width: 28px;
    }
  }
  .ev-month {
    .btn.disabled {
      border: 0 !important;
    }
  }
  .ev-year {
    .btn.disabled {
      border: 0 !important;
    }
  }
}

.datepicker-width-80 {
  width: 80px !important;
  font-size: 12px;
  line-height: 1.5;
  padding: 5px 10px;
}

.datepicker-width-120 {
  width: 120px !important;
};
