.whitebox, .whitebox-inner {
  background: white;
  border: 1px solid #ddd;
  padding: 7px;
  &-margin {
    margin-bottom: 15px;
  }
  h4 {
    font-weight: 400 !important;
  }
  h5 {
    padding-left: 30%;
    margin-top: 0;
  }
  h6 {
    margin-top: 20px;
  }
  hr {
    margin: 10px 0;
  }
  .bordertop {
    border-top: 1px solid #eee;
    padding-top: 7px;
  }
}
