body {
  overflow-x: hidden;
}

body.envisiabody {
  color: $fontcolor;
  background-color: $backgroundcolor !important;
  font: {
    family: $font-family-sans-serif;
    weight: 400;
    size: 12px !important;
  }
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  a {
    color: #0477ea;
    text-decoration: none;
    outline: none !important;
    &:visited {
      color: #0477ea;
      text-decoration: none;
    }
  }
  a.btn-default {
    color: $fontcolor !important;
  }
  img {
    border: none !important;
    outline: none !important;
  }
  label {
    font-weight: normal;
  }
  .label {
    font-weight: normal;
    font-size: 90%;
    padding: 2px 6px;
  }
  h1, h2, h3, h4 {
    font-weight: 300;
    padding: 0;
  }
  h1 {
    font-size: 30px;
    margin: 0 0 10px 0;
  }
  h2, h3 {
    font-size: 20px;
    margin: 0 0 10px 0;
  }
  p {
    margin: 0 0 5px 0;
  }
  h4 {
    margin: 0 0 10px 0;
  }
  h6, .h6 {
    font-size: 14px;
  }
  .selectedgray {
    background: #e9e9e9 !important;
  }
  .selected {
    background: $selected-color !important;
    color: $selected-font-color !important;
  }

  pre {
    font: {
      family: $font-family-sans-serif;
      weight: 400;
      size: 12px;
    }
    border: 0;
    padding: 0;
    background: transparent;
  }
  .btn-sm {
    padding: 4px 7px;
  }
  .alert {
    padding: 7px;
  }
  .btn-info:hover {
    color: #ffffff;
  }
  .bold {
    font-weight: bold;
  }
  .form-horizontal {
    .control-label {
      text-align: left;
    }
  }
  .pointer {
    cursor: pointer;
  }
  .btn-after-headline {
    font-size: 13px;
    margin: -8px 0 10px;
    padding: 0 !important;
  }
  .btn-flag {
    padding: 3px 4px 5px !important;
    img {
      height: 18px;
    }
  }
  .btn-filter {
    font-size: 12px;
    padding: 3px 1px 3px 9px;
    position: relative;
    .badge {
      border-radius: 0;
      margin: -3px 0 -3px 5px;
      padding: 5px;
      position: relative;
    }
  }
  .filter-row {
    display: table;
    width: 100%;
    margin-bottom: 5px;
    .btn {
      border-radius: 0;
    }
    .filter-left, .filter-right {
      display: table-cell;
      vertical-align: top;
    }
    .filter-right {
      text-align: right;
    }
    select {
      option {
        padding: 5px 3px;
        font-size: 16px;
      }
    }
  }
  .form-input {
    border: 1px solid #eee;
    height: auto;
    padding: 2px 4px !important;
    text-align: right;
  }
  .form-alert {
    background-color: #f2dede;
    border-color: #ebccd1;
    color: #a94442;
  }

  .fa-file-pdf-o, .fa-exclamation-triangle {
    color: #e41e00 !important;
  }

  .align-right {
    text-align: right;
  }
}
