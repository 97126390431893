.article-fake-sds-field {
  height: 30px;
}

.article-form-item-customer {
  margin: 10px 0 0 -22px !important;
}

.article-form-item {
  display: table;
  width: 100%;
  margin-bottom: 8px;
  &:after {
    clear: both;
  }

  .article-form-item-label {
    display: table-cell;
    text-align: right;
    vertical-align: middle;
  }
  .article-form-item-label:not(.h5) {
    color: #888;
  }
  .article-form-item-label:not(.no-fixed-width) {
    width: 30%;
  }
  .article-form-item-data {
    display: table-cell;
    vertical-align: middle;
    .col-sm-6 {
      margin: 0;
      padding: 0 !important;
    }
  }
  .article-form-item-data:not(.in-title) {
    padding-left: 15px;
  }
  .article-form-item-data:not(.no-fixed-width) {
    width: 70%;
  }
  .article-form-item-data.in-title {
    padding-left: 5px;
  }
  .article-form-item-values {
    display: table-cell;
    vertical-align: middle;
  }
  .article-form-item-values:not(.no-fixed-width) {
    width: 60%;
  }
  .article-form-item-extra {
    width: 40%;
    display: table-cell;
    vertical-align: middle;
    padding-left: 15px;
    input {
      width: 100%;
    }
  }
  .article-form-item-reduced {
    vertical-align: middle;
    display: table;
    padding-left: 15px;
    input {
      display: table-cell;
      width: 50%;
    }
  }
  .article-form-item-extra-long {
    width: 100%;
    display: table;
    vertical-align: middle;
    padding-left: 0;
    label {
      display: table-cell;
      width: 135px;
      text-align: right;
      color: #888;
      vertical-align: middle;
      padding-right: 15px;
    }
    input {
      width: 100%;
      display: table-cell;
    }
  }

  .article-form-data-content {
    float: left;
    .fa-check, .fa-times {
      color: #5CB85C;
      font-size: 15px;
    }
    .fa-times {
      color: #D9534F;
    }
    .form-control {
      padding: 3px 4px;
      height: 30px;
    }
    label {
      padding: 4px 5px 0 0;
      margin: 0;
    }
    input[type="checkbox"] {
      height: 20px;
      width: 20px;
    }
    .fixed-width {
      width: 90px;
      &-md {
        width: 70px;
      }
      &-sm {
        width: 60px;
      }
      &-xs {
        width: 50px;
      }
    }
    .input-highlighted {
      font-weight: bold;
    }
  }
  .article-form-data-unit {
    display: inline-block;
    margin-left: 10px;
  }
  .article-form-data-unit-block {
    display: inline-block;
    padding: 10px 6px 8px;
    font-size: 10px;
    font-weight: normal;
    line-height: 1;
    color: #555;
    text-align: center;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .article-form-data-content-placeholder {
    width: 20px;
  }
  .data-group {
    display: inline-block;
    .article-form-data-content {
      &:first-child {
        .article-form-data-unit-block {
          border-bottom-right-radius: 0;
          border-top-right-radius: 0;
          margin-right: -2px;
        }
      }
      .article-form-data-unit-block-border {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        margin-left: -2px;
      }
      &:last-child {
        .article-form-data-unit-block {
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
          margin-left: -2px;
        }
      }
    }
  }
}

.article-form-item-extra-values {
  @extend .article-form-item;
  .article-form-item-label {
    width: 120px;
  }
  .article-form-item-label, .article-form-item-data {
    display: inline-block;
  }
  .article-form-item-data {
    width: auto;
  }
  .article-form-item-extra {

  }
}

.article-matrix {
  width: auto;
  th {
    border-top: 0 !important;
    padding: 0 15px 5px 15px !important;
    text-align: center;
  }
  td {
    vertical-align: middle !important;
    padding: 3px 15px !important;
    text-align: center;
    &:first-child {
      padding-left: 5px !important;
      text-align: left !important;
    }
  }
  tr:nth-child(even) {
    background: #FDFEF9;
  }
  .gray {
    background: #f5f5f5;
  }
  input[type="checkbox"] {
    height: 20px;
    width: 20px;
  }
}

.article-customer {
  display: block;
  margin-top: 30px !important;
  h4 {
    font-size: 14px !important;
  }
}

.article-head {
  color: #3c763d !important;
  background-color: #dff0d8;
  border: 1px solid #d6e9c6;
  display: table;
  padding: 5px 0 5px;
  width: 100%;

  &-col {;
    float: left;
    padding: 0 10px;
    min-width: 230px;
  }
  .article-form-item {
    margin-bottom: 3px;
    &-label {
      color: #3c763d !important;
      .fa {
        margin-right: 2px;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
  &-note {
    textarea {
      width: 480px;
      height: 63px;
      font-size: 11px;
    }
    label {
      display: none;
    }
  }
  &-alert {
    &-danger {
      background-color: #f2dede;
      border-color: #ebccd1;
      label, .article-form-item-label {
        color: #a94442 !important;
      }
    }
    &-warning {
      background-color: #fcf8e3;
      border-color: #faebcc;
      label, .article-form-item-label {
        color: #8a6d3b !important;
      }
    }
  }
}

.article-hide {
  background: #f6f6f6 none repeat scroll 0 0;
  position: fixed;
  height: 50px;
  z-index: 10;
  width: 100%;
  top: 30px;
}

.article-fixed-head {
  position: relative;
}

.article-fixed-head {
  background: #f6f6f6 none repeat scroll 0 0;
  left: 0;
  //padding: 5px 10px 5px 146px;
  position: sticky;
  top: 50px;
  width: 100%;
  z-index: 100;
}

.article-content {
  .alert {
    margin: 0;
    padding: 10px;
  }
}

.article-production-max-height {
  height: 780px;
  overflow: auto;
}

.article-pre {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-wrap {
  white-space: nowrap !important;
}

.fontsize10 {
  font-size: 10px;
}

.row.mb-10 {
  margin-bottom:10px;
}

.align-middle {
  vertical-align: middle !important;
}

