.akquiselist {
  .plan {
    background: #FCF8E3 !important;
  }
  .akquiselist-item {
    float: left;
    clear: left;
    padding: 5px;
    border: 1px solid #ccc;
    width: 100%;
    background-color: #fff;
    .akquise-detail {
      float: left;
      margin-right: 20px;
      min-width: 160px;
    }
    .akquise-text {
      pre {
        margin: 0;
        white-space: normal;
      }
      //float: left;
    }
    .akquise-status {
      margin-bottom: 3px;
    }
    .akquise-customer {
      float: left;
      margin-right: 20px;
      min-width: 240px;
      .akquise-customer-address {
        font-size: 12px;
      }
    }
  }

}
