@import "variables";

.modal {
  z-index: 9999 !important;
}

.modal-content {
  background-color: $backgroundcolor !important;
  .modal-header {
    border: none;
    h3 {
      margin: 0;
    }
  }
  .modal-body {
    padding: 0 15px;
  }
  .modal-footer {
    border: none;
  }
}

.modal-smm {
  width: 402px !important;
}

.modal-slg {
  width: 1025px !important;
}

.modal-mg {
  width: 710px !important;
}

.modal-xl {
  width: 1400px !important;
}

/** Start of ng-bootstrap compat (boostrap 3 to 4 adapter) and modal styles */
.modal-backdrop.show {
  opacity: .5 !important;
}

.fade.show {
  opacity: 1;
}

.modal.fade .modal-dialog{
  transform: none !important;
  transition: none  !important;
}

.modal2-normal {
  .modal-dialog {
    margin: 30px auto;
  }
}

.modal2-xlg {
  .modal-dialog {
    margin: 30px auto;
    width: 1850px !important;
  }
}

.modal2-slg {
  .modal-dialog {
    margin: 30px auto;
    width: 1025px !important;
  }
}

.modal2-sxlg {
  .modal-dialog {
    margin: 30px auto;
    width: 1275px !important;
  }
}

.modal2-full {
  .modal-dialog {
    margin: 30px auto;
    width: 100% !important;
  }
}

.modal2-lg {
  .modal-dialog {
    margin: 30px auto;
    width: 1100px !important;
  }
}

.modal2-smm {
  .modal-dialog {
    margin: 30px auto;
    width: 402px !important;
  }
}

.modal2-mm {
  .modal-dialog {
    margin: 30px auto;
  }
}

.modal2-mg {
  .modal-dialog {
    width: 710px !important;
  }
}

.modal2-xl {
  .modal-dialog {
    width: 1400px !important;
  }
}

.modal2-1200 {
  .modal-dialog {
    width: 1200px !important;
  }
}

.modal2-article {
  .modal-dialog {
    width: 1024px !important;
  }
}

.modal2-akquise {
  .modal-dialog {
    width: 665px !important;
  }
}

.wrapper {
  white-space: nowrap;
  display: inline-block;
}

.modal2-cn-order {
  .modal-dialog {
    width: 1550px !important;
  }
}
