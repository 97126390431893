.right-pad20 {
  padding-right: 20px;
}
.pricechecktable {
  td {
    padding: 2px 6px !important;
    input {
      padding: 1px 2px !important;
      border: 1px solid #ccc !important;
      font-size: 12px;
      height: auto;
      text-align: right;
      width: 55px;
    }
    button, .iconbutton {
      font-size: 19px;
      line-height: 19px;
      padding: 0;
    }
  }
  .pricerelease-check {
    background: #ffffff;
    padding: 2px 5px;
    .fa-check {
      color: #85D52A;
    }
  }
  .btn-refresh {
    padding: 0;
    font-size: 20px;
  }
}

.pricecheckform {
  .input-group-addon {
    font-size: 13px;
    padding: 6px 4px;
  }
}

.pricecheck {

  &-left {
    display: block;
    margin: 0 20px 20px 0;
    width: 400px;
  }
  &-pricelist {
    min-width: 715px;
    &-de, &-chn {
      display: block;
    }
  }
}

@media (min-width: 1200px) {
  .pricecheck {
    &-left {
      float: left;
      margin-right: 20px;
      width: 350px;
    }
    &-pricelist {
      float: left;
      &-de {
        display: block;
      }
      &-chn {
        display: block;
      }
    }
  }
}

@media (min-width: 1800px) {
  .pricecheck {
    &-left {
      float: left;
      margin-right: 20px;
      width: 350px;
    }
    &-pricelist {
      float: left;
      &-de {
        float: left;
        .whitetable {
          margin-right: 20px;
        }

      }
      &-chn {
        float: left;
      }
    }
  }
}

.pricecheckarticle {
  min-height: 180px;
}

.pricecheckarticle .whitetable tr td a {
  color: #0477ea !important;
}

.pricecheck-modal-row {
  display: table;
  width: 100%;
  margin-bottom: 10px;

  .pricecheck-modal-empty {
    display: table-cell;
    width: 71%;
    h3 {
      margin-bottom: 0;
    }
  }
  .pricecheck-modal-item {
    @extend .pricecheck-modal-empty;
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    &:last-child {
      border-left: 0;
    }
    .headline {
      font-size: 15px;
      margin-bottom: 3px;
      img {
        width: 20px;
      }
    }
    .red_font {
      margin-top: 5px;
    }
  }

  .pricecheck-modal-item-choice {
    @extend .pricecheck-modal-empty;
    padding: 10px;
    tbody {
      background: white;
    }
    &:last-child {
      border-left: 0;
    }
    .headline {
      font-size: 15px;
      margin-bottom: 3px;
      img {
        width: 20px;
      }
    }
    .red_font {
      margin-top: 5px;
    }
  }

}

.pricecheck-box {
  margin-bottom: 15px;
  &-row {
    display: table;
    margin: 0 -5px 2px;
    width: 100%;
  }
  @for $i from 1 through 100 {
    &-col-#{$i} {
      width: $i * 1%;
      float: left;
      padding: 0 5px;
    }
  }
  &-col {
    float: left;
    padding: 0 5px;
  }
  input, select {
    padding: 4px 5px;
    height: auto;
  }
  .form-group {
    margin-bottom: 3px;
  }
  label {
    margin-bottom: 0;
  }
}
