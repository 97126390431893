/* ------  User list / User Card ------ */
@import "variables";

.userlist {
  border-top: 1px solid #ccc;

  .self {
    background: grey !important;
  }

  .userlist-item {
    float: left;
    clear: left;
    background: white;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 10px;
    width: 100%;
    color: $fontcolor;
    &:hover {
      cursor: pointer;
    }

    .user-image {
      float: left;
      width: 50px;
      height: 50px;
      background: #eee;
      border: 1px solid #ccc;
      margin-right: 10px;
      color: #999;
      font-size: 32px;
      text-align: center;
      vertical-align: middle;
    }
    .user-detail {
      float: left;
      .user-name {
        float: left;
        clear: left;
      }
      .user-id, .user-mail {
        float: left;
        clear: left;
        font-size: 10px;
        color: #909090;
      }

    }
    .user-last-login {
      float: right;
      clear: right;
      font-size: 10px;
      color: #666;
      text-align: right;
    }
    .user-status {
      float: right;
      clear: right;
      margin-bottom: 5px;
    }
  }
  .inactive {
    background: #F5E3E6 !important;
    color: #a94442;
    .user-id, .user-last-login {
      color: #a94442 !important;
    }
  }
}

.user-side-view {
  float: left;
  .user-item {
    background: white;
    border: 1px solid #ccc;
    padding: 10px;

    &-image {
      display: inline-block;
      width: 100px;
      height: 100px;
      background: #eee;
      border: 1px solid #ccc;
      margin-right: 10px;
      color: #999;
      font-size: 65px;
      text-align: center;
      vertical-align: top;
    }
    &-name {
      display: inline-block;
      &-detail {
        display: block;
        width: 348px;
        font-size: 11px;
        &-first {
          display: inline-block;
          width: 110px;
        }
        &-last {
          display: inline-block;
          width: 235px;
        }
      }
    }

  }
}

.permission-list {
  float: left;
  clear: both;
  border-bottom: 1px solid #ccc;
  .permission-item {
    float: left;
    clear: left;
    background: white;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    padding: 10px;
    width: 483px;
    color: $fontcolor;
    .permission-info {
      float: left;
      width: 100%;

      .permission-title {
        float: left;
      }
      .permission-status {
        float: right;
        padding: 0 0 0 10px;
      }
      .permission-action {
        float: right;
        font-size: 25px;
        .fa {
          float: right;
          margin-top: -3px;
        }
        .fa-toggle-on {
          color: #3c763d !important;
        }
      }
    }
    .permission-detail {
      float: left;
      font-size: 10px;
      color: #666;
      width: 100%;
      div {
        float: left;
        width: 100%;
      }
    }

  }
}
