// Font-Awesome
$fa-font-path : "~font-awesome/fonts";

// Envisia
$font-family-sans-serif: -apple-system, ".SFNSText-Regular", "San Francisco", "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", sans-serif;
$fontcolor: #333;
$backgroundcolor: #f6f6f6;


$selected-color: #DFF0D8;
$selected-font-color: black;

$light-blue-bg-color: #5bc0de;

