.article-state-red {
  background: #D9534F !important;
  color: #fff !important;
}

.article-state-orange {
  background: #f9a600 !important;
  color: #fff !important;
}

.article-state-green {
  background: #5CB85C !important;
  color: #fff !important;
}

.row-no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.top-buffer {
  margin-top: 10px;
}

.top-inner-buffer {
  padding-top: 10px;
}

#article-navigation {
  .navigation {
    margin-bottom: 5px;
    a {
      color: #333333;
      text-decoration: none;
      font-size: 20px;
      font-weight: 300;
      margin-right: 20px;
    }
    .active {
      color: #428BCA;
    }
  }
}

.input-align-left {
  input {
    text-align: left;
  }
}

.input-align-right {
  input {
    text-align: right;
  }
}

.col-padding {
  padding-top: 20px;
}

.row-flexbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.placeholder-left {
  margin-left: 35px !important;
}
