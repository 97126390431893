/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "sass/variables";
@import "sass/bootstrap";
@import "font-awesome/scss/font-awesome";
@import "sass/datepicker";
@import "sass/login";
@import "sass/user";
@import "sass/article";
@import "sass/finder";
@import "sass/layout";
@import "sass/body";
@import "sass/white";
@import "sass/pricecheck";
@import "sass/customer";
@import "sass/order";
@import "sass/akquise";
@import "sass/modal";
@import "sass/ba";
@import "sass/badashboard";
@import "sass/react-autosuggest";

@layer base {
  img {
    display: initial;
    max-width: initial;
  }
}

#content-root {
  padding: 0 !important;
  margin: 0 !important;
}

// angularjs cloaking, see: https://docs.angularjs.org/api/ng/directive/ngCloak
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important;
}

/** Workflow */
.alert-primary {
  @extend .alert-info;
}

.alert-purple {
  @extend .alert-success;
  color: white;
  background-color: purple;
}

.alert-rose {
  @extend .alert-success;
  color: #fff;
  background-color: #E83E8C;
}

.alert-grey {
  @extend .alert-info;
  color: black;
  background-color: #D6D9D8;
}

.alert-default {
  @extend .alert-info;
  color: #777777;
  background-color: #D6D9D8;
}

/** End Workflow */

/**
 * Starting Styles
 */
.no-margin-fg {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.datepicker.dropdown-menu {
  button {
    outline: none !important;
    border: 0 !important;
  }
}

.deleted {
  text-decoration: line-through;
}

.scrolling-if-needed {
  overflow-y: auto !important;
}

.ev-blue-color {
  color: #0477ea;
}

.tooltip {
  .tooltip-inner {
    font-size: 13px;
    padding: 5px;
    font-weight: normal !important;
  }
}

.small-form {
  .smaller-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .row {
    margin: 0 -10px;

    .form-group {
      margin: 0 -10px 6px;

      input, select {
        height: auto;
        padding-top: 4px;
        padding-bottom: 4px;
      }
    }

    @each $typ in (xs, sm, md, lg) {
      @for $i from 1 through 12 {
        .col-#{$typ}-#{$i} {
          @extend .smaller-padding;
        }
      }
    }
  }
}

.xsmall-form {
  @extend .small-form;

  .form-group{
    margin-bottom: 5px;

    .form-control{
      padding: 4px 6px;
    }
    .btn-link{
      padding: 0;
    }
  }
}

.storzgreen {
  background-color: #EEF7D8 !important;
}

.storzblue {
  background-color: #e5f5ff !important;
}

.storzgreen_dark {
  background-color: #E4F5BB !important;
}

.storzblue_dark {
  background-color: #CCEAFC !important;
}

.red_alert {
  background-color: #f2dede !important;
  border-color: #ebccd1 !important;
  color: #a94442 !important;
}

.red_alert_input {
  input {
    @extend .red_alert;
  }
}

.min {
  min-height: 50px;
}

.bottom-up {
  bottom: 10px;
  position: relative;
}

.red_font {
  color: #a94442 !important;
}

.gray_font {
  color: #7b7b7b !important;
}

.tablerighthead {
  th {
    text-align: right !important;
  }
}

.inBoxtable {
  margin: 0;

  tr {
    th {
      padding: 0 2px 2px 2px !important;
      background: transparent;
      border: 0 !important;
    }
  }

  tr.no-border {
    td {
      border: 0 !important;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  tr.padding-top {
    td {
      padding-top: 5px;
    }
  }

  td {
    padding: 2px;
  }

  .right {
    text-align: right;
  }

}

.tablemiddle {
  tr {
    th, td {
      vertical-align: middle !important;
    }
  }
}

.bluebox {
  background: #DAEEF7;
  border: 1px solid #ddd;
  margin-left: -1px;

  h5 {
    text-align: center;
  }

  hr {
    margin: 10px 0;
    color: #0150A0;
  }
}

.yellowbox, .yellowbox-inner {
  background: #F1EEDC;
  border: 1px #F2E8D1 solid;
  padding: 7px;

  &-margin {
    margin-bottom: 15px;
  }
}

.yellowbox-no-border {
  background: #F1EEDC;
  padding: 7px;
}

.whitebox-inner {
  float: left;
  width: 100%;
}

.tasknavigation {
  padding: 5px 0 10px 15px;
}

.positionlist {
  padding-left: 15px;
}

.positionnavigation {
  padding-bottom: 15px;
}

.content-box {
  min-height: 220px;

  .row {
    margin-bottom: 5px;
  }

  &-bottom {
    bottom: 20px;
    position: absolute;
  }

  .customer-inside {
    p {
      i {
        width: 25px;
      }
    }
  }

  .input-inside {
    input, textarea, select {
      background: transparent;
      border: 1px solid transparent;
      cursor: pointer;
      padding: 4px 3px;
      font-weight: normal;
      box-shadow: none;
      font-size: 12px;
      // height: 22px;
      &:hover, &:focus {
        border: 1px solid #66afe9;
        border-radius: 3px;
        outline: 0 none;
        background: #eee;
      }
    }
  }
}

.task-customersearch {
  .task-customersearch-form {
    label {
      width: 100%;
    }
  }

  .whitelist {
    max-height: 700px;
    overflow: auto;
  }
}

.wizard-article-list {
  margin-top: 15px;
}

.wizardcustomerForm {
  h3 label {
    font-weight: 300;
  }

  .form-control {
    margin-bottom: 10px;
  }

  .btn {
    margin-top: 10px;
  }
}

.contract-position-list {
  table {
    margin: 0;

    th {
      font-weight: normal;
    }

    td {
      border-bottom: none !important;
      background: #fff;

      a {
        font-size: 20px;
        margin-right: 15px;
      }

      .label {
        float: left;
        padding: 5px 8px;
        margin-top: 4px;
      }

      .fetchcount {
        float: left;
        font-size: 25px;
        margin: -4px 13px -4px 10px;
      }
    }

    .confirm {
      td {
        @extend .alert-success;
        background-image: none;
      }
    }

    .alert-danger {
      td {
        @extend .alert-danger;
        background-image: none;
      }
    }
  }

  .whitetable tbody {
    border: 0 !important;

    &:after {
      content: '';
      display: block;
      height: 20px;
    }
  }
}

.calculationlist {
  width: 350px;
  float: left;
  margin-right: 15px;

  a {
    color: $fontcolor !important;
  }

  .whitelist-item {
    padding: 8px;
  }
}

.contract-history-list {
  .alert-danger {
    background: #F2DEDE !important;
  }

  .fix-date-form {
    color: black;
  }

  .fix-date-box {
    color: black;
    width: 90px;
    border: 1px solid #ddd;
    background: #F6F6F6;
  }

  .document-row {
    display: table;
    width: 100%;

    &-cell {
      display: table-cell;
      vertical-align: top;

      .fa-file-pdf-o {
        margin-right: 5px;
      }

      .label-button {
        padding: 5px;
      }
    }
  }
}

#loading {
  background: #5cb85c;
  color: #ffffff;
}

#saving {
  background: #d9edf7;
  color: #31708f;
}

.loading {
  background: #f1f1f1;
  bottom: 0;
  left: 0;
  opacity: 0.7;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;

  &-content {
    color: #555;
    font-size: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -111px;
    margin-top: -66px;
    z-index: 1001;

    &-text, &-icon {
      display: block;
      text-align: center;
    }

    &-icon {
      font-size: 55px;
    }
  }
}

.headalert {
  font-weight: 300;
  font-size: 20px;
  position: fixed;
  padding: 5px 15px;
  top: -2px;
  left: 40%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  z-index: 1001;
  white-space: nowrap;
}

.click {
  cursor: pointer;
}

.content {
  .contentbox {
    background-color: #FDFDFD;
    border: 1px solid #CCCCCC;
    display: table;
    margin: 0 0 10px 0;
    padding: 10px;
    width: 100%;

    input {
      background: transparent;
      box-shadow: none;
      font-size: 12px;
    }

    .col-sm-1 {
      .fa {
        font-size: 18px;
        padding-top: 7px;
      }
    }
  }

  .col-sm-6 {
    padding-left: 0;
  }
}

.tab-content {
  margin-top: 15px;
}

.bold {
  font-weight: bold;
}

/* Article V2 Block */

.small-input {
  input {
    font-size: 13px;
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}

.margin-bottom {
  margin-bottom: 15px !important;
}

.placeholder-top {
  margin-top: 20px !important;
}

.inline-block {
  display: inline-block;
}

.white-box-list {
  float: left;
  clear: left;

  div {
    padding: 5px;
    background: #fff;
    border: 1px solid #ccc;
    margin: 0 0 -1px 0;
    width: 270px;
    float: left;

    label {
      float: left;
      width: 115px;
    }

    .fa-check {
      color: green;
    }

    .fa-times {
      color: red;
    }

    span {
      margin-right: 5px;
    }

    i {
      margin-right: 10px;
    }
  }
}

.offer-article-info {
  @extend .white-box-list;

  div {
    margin-right: 15px;
  }
}

.offer-article-spec {
  @extend .white-box-list;
  float: left;
  clear: left;
  margin-top: 16px;
  margin-right: 15px;

  div {
    clear: left;
  }
}

.darkredlink {
  color: #843534 !important;
  font-weight: bold;

  img {
    margin-left: 2px;
  }
}

#testserver {
  left: 0;
  position: fixed;
  bottom: 0;
  background-color: red;
  z-index: 1;
}

.green {
  color: #4cae4c;
}

.btn-purple {
  @include button-variant(#fff, purple, #ccc);

  .badge {
    @extend .badge;
    color: #333;
    background-color: #fff;
  }
}

.btn-yellow {
  @include button-variant(#000, #ffdd00, #ccc);

  .badge {
    @extend .badge;
    color: #fff;
    background-color: #333;
  }
}

.label-purple {
  @extend .label-default;
  background-color: purple;
}

.label-gold {
  @extend .label-default;
  background-color: rgb(253, 195, 19);
}

.label-orange {
  @extend .label-default;
  color: #fff;
  background-color: #ff8238 !important;
}

/* End Article V2 */

.envisiasite {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #f6f6f6;
  font-size: 20px;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 300;
}

.errorsite {
  width: 100%;
  text-align: center;
  margin-top: 13%;
}

.error-img {
  display: inline-block;
  font-size: 90px;
  vertical-align: top;
  height: 100px;
  margin-bottom: 30px;
}

.error-img i {
  vertical-align: top;
  margin-top: 1px;
}

.error-status {
  display: inline-block;
  text-align: left;
  margin-left: 40px;
  vertical-align: top;
  margin-bottom: 30px;
}

.error-status h2 {
  font-size: 25px !important;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
}

.error-status h1 {
  font-size: 60px !important;
  font-weight: 300;
  margin: 0;
}

.error-message {
  display: block;
  line-height: 40px;
  padding: 0 10px;
}

.error-link {
  display: block;
  margin-top: 40px;

}

.error-link .btn-lg {
  margin: 0 5px;
  padding: 8px 16px;
}

.error-link a i {
  margin-right: 10px;
}

.footer {
  position: fixed;
  bottom: 5px;
  width: 100%;
  text-align: center;
}

.footer a {
  color: #333 !important;
}

.label-button {
  border: 1px solid transparent;
}

.greyed-out {
  background: #efefef !important;
}

.fade-chkbx {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

/* The starting CSS styles for the enter animation */
.fade-chkbx.ng-enter {
  transition: 0.01s linear all;
}

/* now the element will fade out before it is removed from the DOM */
.fade-chkbx.ng-leave {
  transition: 0.02s linear all;
}

.red-font {
  color: red !important;
}

.borderless-top {
  border-top: 0 !important;
}

.marginless-bottom {
  margin-bottom: 0 !important;
}

.marginless {
  margin: 0 !important;
}

.fixed-width-150 {
  width: 150px !important;
}

.fixed-width-400 {
  width: 400px !important;
}

.margin-up-down-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.color-green {
  color: green !important;
}

.inventory {

  .inventory-fat {
    font-weight: bold;
  }

  .inventory-green {
    @extend .inventory-fat;
    color: green;
  }

  .inventory-red {
    @extend .inventory-fat;
    color: red;
  }

  .inventory-black {
    @extend .inventory-fat;
    color: black;
  }

}

.alert-special {
  text-align: center;
  z-index: 100000;
}

.align-special {
  text-align: center;
  vertical-align: middle;
}

.float-right {
  float: right;
  padding-left: 10px;
}

.float-left {
  float: left;
  padding-right: 10px;
}

.clear-both {
  clear: both;
}

.mid-label {
  padding: 10px !important;
  font-size: 14px !important;
  white-space: normal;
  display: block;
  text-align: left;
  line-height: 20px;
}

.ev-button {
  border: none;
  background: none;
  padding: 0;
}

.link-blue {
  color: blue;
}

.chrissies {
  .margin-top-bottom-10 {
    margin: 10px 0;
  }

  .cost-box {
    margin-top: 5px;

    .cost-box-button {
      width: 100% !important;
    }
  }

  .express-box {
    margin-top: 5px;

    .express-box-button {
      width: 100% !important;
    }
  }

  .padding-top-5 {
    padding-top: 5px;
  }

  .left-align {
    text-align: left;
    vertical-align: middle;
  }

  .height-30 {
    height: 30px;
  }

  .width-100 {
    width: 100px;
  }

  .width-200 {
    width: 200px;
  }

  .width-25 {
    width: 25px;
  }

  .width-35 {
    width: 35px !important;
  }

  .font-size-20 {
    font-size: 20px;
  }
}

.form-special .form-group {
  display: inline-block !important;
  margin-bottom: 0 !important;
  vertical-align: middle !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.form-special-margin {
  margin-top: 5px;
  margin-bottom: 5px;
}

.picker-year {
  width: 60px;
}

.selectedTemplate {
  td {
    color: black;
    background-color: $selected-color !important;
  }

  .btn-link {
    color: black;
  }
}

.dataTable {

  .td_grey {
    background-color: #EFEFEF;
    text-align: right;
  }

  .td_blue {
    color: black;
    background-color: #E5F5FF;
    text-align: right;
  }

  .td_green {
    color: black;
    background-color: #EEF7D8;
    text-align: right;
  }

  .td_white {
    text-align: right;
  }

  .td_special {
    text-align: left;
  }

  .td_footer {
    border-top: 2px solid black;
    font-weight: bold;
  }

  .td_bold {
    font-weight: bold;
  }

}

.analysisTable {
  .td_grey {
    background-color: #EFEFEF;
  }

  .td_blue {
    color: black;
    background-color: #E5F5FF;
  }

  .td_green {
    color: black;
    background-color: #EEF7D8;
  }

  .td_white {
  }

  .td_special {
  }

  .td_footer {
    border-top: 2px solid black;
    font-weight: bold;
  }

  .td_bold {
    font-weight: bold;
  }

  .td_numeric {
    text-align: right;
  }
}

.red-font {
  color: red;
}

.inventory-headline {
  font-size: 14px;
}

.analysis-filter {
  &-row {
    display: block;
    padding-bottom: 10px;
  }

  &-box {
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    margin-right: 10px;
    background-color: #fff;
    border: 1px solid #ddd;

    h3 {
      display: block;
      margin: 0;
    }

    &-content {
      display: block;
      margin-top: 10px;
    }

  }

  &-action {
    display: inline-block;
    font-size: 16px;
    padding: 6px 15px !important;
    margin-right: 10px;
  }
}

.sdsgenerationbox {
  text-align: center;
  font-size: 15px;

  img {
    height: 20px;
    margin: 10px;
  }
}

.label-table {
  tr {
    td {
      .form-control {
        @include box-shadow(none);
        background: transparent;
      }

      input[readonly] {
        cursor: inherit;
        width: 20px;

        &:hover, &:focus {
          @include box-shadow(none);
          border: 0 solid transparent;
          border-radius: 0;
          outline: 0 none;
          background: transparent;
        }
      }

      input, textarea {
        @include box-shadow(none);
        background: transparent;
        border: 1px solid transparent;
        cursor: pointer;
        padding: 4px 3px;
        font-weight: normal;
        height: 24px;

        &:hover, &:focus {
          border: 1px solid #66afe9;
          border-radius: 3px;
          outline: 0 none;
          background: #eee;
        }
      }
    }
  }

}

/** Start of ng-bootstrap compat and modal styles */
.nav .nav-link.active {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
  color: #555;
}

.nav .nav-link.disabled {
  background-color: #eee;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
  cursor: not-allowed;
  color: #777777;
}

#settings {
  .dropdown-menu {
    left: -140px !important;

    a {
      padding: 2px 0 !important;

      button {
        padding: 5px 1.5rem;
      }
    }
  }
}

.d-inline-block {
  display: inline-block !important;
}

.left-space {
  float: left;
  margin-left: 10px;
}

.fa-picker-size {
  float: left;
  margin: 6px 10px 6px 40px;
  font-size: 20px;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background: none;
  border: 0;

  &:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #66afe9
  }

  div {
    display: inline-block;
    padding-right: 4px;
  }
  .icon {
    width: 22px;
    padding-right: 30px;
  }
}

/** Drag & Drop */
.dnd-drag-start {
  -moz-transform: scale(0.8);
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  opacity: 0.7;
  border: 2px dashed #000;
}

.dnd-drag-enter {
  opacity: 0.7;
  border: 2px dashed #000;
}

.dnd-drag-over {
  border: 2px dashed #000;
}

.dnd-sortable-drag {
  -moz-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0.7;
  border: 1px dashed #000;
}

.panel-min-height {
  min-height: 58px;
}

.dnd-drag-item {
  padding: 5px 10px;
  margin-bottom: 8px;

  .red_alert {
    background: transparent;
    border: 0;
  }
}

.tooltip.show {
  opacity: .9;
}

.ml-10 {
  margin-left: 10px;
}

.business-order-create-totals {
  padding: 20px 0;

  &-title {
    font-size: 14px;
    display: block;
  }

  &-date {
    font-size: 17px;
    font-weight: bold;
  }

  .red_alert {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.red-bar {
  z-index: 1002;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: block;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  height: 40px;
}

.notes-truncated {
  text-align: left;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 6ch;
  background: #dc3545;
  color: #fff;
  padding: 1px 3px;
  border-radius: 3px;
}

.alri > label {
  text-align: left !important;
}

.ng-invalid:not(form) .row-field {
  background-color: #f2dede !important;
  border-color: #ebccd1 !important;
  color: #a94442 !important;
}

.hide-icon {
  opacity: 0;
}

.status-change {
  display: block;
  margin: auto;
  td {padding: 3px;}
}

.nowrap {
  white-space: nowrap;
}

.star-yellow {
  color: #ffbf00;
}

.bg-board-yellow {
  background-color: #FFFF9B !important;
}

.bg-copper-red {
  background-color: #F78C8A !important;
}

.bg-gray {
  background-color: #E3E3E3 !important;
}


/**
* Mainly Multilayer styling
**/

// Dropdown button for adding Parts
.dropdown-button {
  position: relative;
  width: 100%;
  height: 100%;
}

.dropdown-button .dropdown-button-inner {
  position: absolute;
  bottom: -20px;
  font-size: 16px !important;
}

.dropdown-button .ddc {
  position: absolute;
  transform: translate(18px, -21px);
  padding: 0 2px;
  background-color: #AAAAAA;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-button .ddc div {
  margin: 2px 0;
  padding: 0 5px;
}

// Color classes for parts
.color-class {
  color: white;
}

.color-class.gray {
  background-color: #999999;
}

.color-class.yellow {
  background-color: #F0E03E;
  color: black;
}

.color-class.cu-foil-red {
  background-color: #951F17;
}

.color-class.cu-kasch-red {
  background-color: #951F17;
}

.color-class.cu-plating-red {
  background-color: #CF372F;
}

.color-class.lacquer-green {
  background-color: #2e4e11;
}

.color-class.prepreg-orange {
  background-color: #E19623;
}

.color-class.mirror-silver {
  background-color: #C0C0C0;
}

.drill-cell {
  padding: 0 !important;
  width: 20px;
}

.drill-cell div {
  text-align: center;
}

.drill-cell.drilled.dk {
  background-color: #000000;
  border: none !important;
}

.drill-cell.drilled.microvia {
  background-color: #AAAAAA;
  border: none !important;
}

.drill-cell.drilled.viafilled {
  background-color: #666666;
  border: none !important;
}

.drill-cell.selected {
  background-color: lightblue;
  border: none !important;
}

.drill-cell.rotated .drill-cell-outer {
  width: 8px;
  transform-origin: 0 0;
  transform: rotate(-90deg);
}

.drill-cell.rotated .drill-cell-inner {
  display: inline-block;
  position: relative;
  top: -2px;
  left: -18px;
  width: 35px;
  font-size: 10px;
  vertical-align: middle;
  text-align: left;
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes kf-blue-gradient-mix {
  0% { background-color: #1993CE; }
  100% { background-color: #002468; }
}

.color-class.blue-gradient-mix {
  animation: kf-blue-gradient-mix 1s linear forwards paused;
  animation-delay: 0s;
  color: white;
  background-color: currentColor;
}

div.rounded-full-left {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  margin: 0 !important;
  padding: 1px 8px !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

div.rounded-full-right {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  margin: 0 !important;
  padding: 1px 8px !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

div.rounded-full-center {
  margin: 0 !important;
  padding: 1px 8px !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}

div.rounded-full {
  border-radius: 3px;
  margin: 0 !important;
  padding: 1px 1px !important;
  width: 100% !important;
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

td.height-fix {
  height: 1px !important;
  padding: 1px 0 !important;
}
// We need to overrule height with 100% when working with firefox
@supports (-moz-appearance:none) {
  td.height-fix {
    height: 100% !important;
    padding: 1px 0 !important;
  }
}

.height-fix-inner {
  display: inline-table !important;
  width: 100% !important;
  height: 100% !important;
}

div.flex {
  display: flex;
}

div.align-start {
  align-items: flex-start;
}

div.align-center {
  align-items: center;
}

div.align-end {
  align-items: flex-end;
}

div.justify-start {
  justify-content: flex-start;
}

div.justify-center {
  justify-content: center;
}

div.justify-end {
  justify-content: flex-end;
}

div.fit-all {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input.number-input-no-arrows::-webkit-outer-spin-button,
input.number-input-no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number].number-input-no-arrows {
  -moz-appearance: textfield;
}

.border-dashed-top {
  border-top: dashed black 1px !important;
}

.border-dashed-bottom {
  border-bottom: dashed black 1px !important;
}

tr:last-child td.border-dashed-bottom {
  border-bottom: dashed black 1px !important;
}

.border-none-top {
  border-top: none !important;
}

.border-none-bottom {
  border-bottom: none !important;
}

tr:last-child td.border-none-bottom {
  border-bottom: none !important;
}

.align-top {
  vertical-align: top !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.btn-link.default-gray {
  color: $btn-link-disabled-color;
  border-color: transparent;
}

.btn-link.default-gray.active {
  color: #0477ea;
}

.btn-link:focus {
  outline: none !important;
}

.fa-stack.subscript {
  width: 1em;
}

.fa-stack.subscript.right .fa-stack-2x {
  font-size: 0.75em;
  position: relative;
  left: unset;
  top: unset;
  bottom: -5px;
  right: -0.6em;
  margin-right: 0;
}

.overflow-ellipsis {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.overflow-ellipsis.no-wrap {
  white-space: nowrap !important;
}

.scrollbar-thin {
  scrollbar-width: thin;
}
