// Copyright (C) 2016 envisia GmbH
// All Rights Reserved.
.finder {
  font-size: 14px;

  .next-button {
    padding: 5px 10px;
  }

  h3 {
    font-weight: normal;
    font-size: 16px;
  }

  .finder-header {
    height: 30px;
    padding: 5px;
  }

  .finder-body {
    color: black;

    padding: 5px;
  }

  .content-box {
    display: block;
    padding: 10px;
    background: #ffffff none repeat scroll 0 0 padding-box;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.06);
    border: 1px solid #d9d9d9;
    margin-top: 10px;
    overflow: hidden;
    min-height: 0;
  }

  .content-box-category {
    .base {
      font-weight: 400;
    }
    .sub {
      color: #111;
      .basename {
        color: #999;
      }
    }
  }

  .label-filenumber {
    background-color: #fcf8e3 !important;
    border-color: #faebcc !important;
    color: #8a6d3b !important;
  }

  .content-box-row, .content-box-table-row {
    display: table;
    width: 100%;
  }

  .content-box-tag-row .label {
    display: inline-block;
    font-weight: 500;
    font-size: 100%;
    padding: 5px 8px;
    border: 1px solid #fff;
    margin: 4px 4px 1px 0;
  }

  .content-box-tag-row .label span {
    display: inline-block;
    line-height: 14px;
    margin: 0;
  }

  .label-filenumber a {
    color: #8a6d3b !important;
  }

  .content-box-tag-row .label .tag-delete {
    display: inline-block;
    display: -moz-inline-stack;
    line-height: 14px;
    margin: 0 0 0 5px;
  }

  .content-box-tag-row .label i {
    line-height: 15px;
    margin-right: 5px;
    float: left;
  }

  .content-box-table-row {
    border-bottom: 1px solid #e2e2e2;
    margin-bottom: 5px;
    padding-bottom: 5px;
  }

  .content-box-table-row:last-child {
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .content-box-cell {
    display: table-cell;
    vertical-align: middle;
  }

  .content-box-submit-cell {
    text-align: right;
    width: 100px;
    padding-left: 10px;
  }

  .btn-success-flat {
    background: transparent;
    border-color: #49a62c;
    color: #49a62c;
    padding: 4px 8px;
  }

  .btn-success-flat:hover {
    background: #f9f9f9 none repeat scroll 0 0;
  }

  .form-control {
    padding: 6px 8px;
    line-height: 15px;
    box-shadow: none;
    border-color: #d9d9d9;
    border-radius: 2px;
    font-weight: 400;
    color: #111;
  }

  .form-control-flat, .form-control {
    background: transparent none repeat scroll 0 0;
    border-left: 0 none;
    border-radius: 0;
    border-right: 0 none;
    border-top: 0 none;
    height: 25px;
    line-height: 15px;
    padding: 2px 2px 3px 2px;
  }




  .form-control-flat {
    height: 30px;
    margin: 0 !important;
    padding-top: 5px !important;
  }

  .form-control-flat:focus, .form-control:focus {
    border-color: #1d97eb;
    padding: 2px;
    border-width: 2px;
  }

  .form-control-flat:hover, .form-control:hover {
    border-color: #9ECBE7;
  }

  .content-box-cell-action {
    width: 30px;
    text-align: right;
    height: 26px;
    a {
      color: #1d97eb;
    }

    i {
      font-size: 25px;
    }
    .fa-toggle-off {
      color: #F32C1E !important;
    }

    .fa-toggle-on {
      color: #6fb858 !important;
    }
  }

  .max-width {
    display: table;
    width: 100%;
  }

  .file-container {
    border: 2px dashed #4B4B4B;

    margin-top: 5px;
    margin-bottom: 5px;
    display: table-cell;
    background: #f4f4f4;
    width: 100%;
    height: 150px;
    vertical-align: middle;
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    color: #4B4B4B;
  }

  .upload-typ-item {
    text-align: center;
    min-height: 156px;
  }

  .upload-typ-item .upload-item-icon {
    display: block;
    text-align: center;
    margin-bottom: 5px;
    font-size: 70px;
    color: #999;
  }

  .upload-typ-item:hover .upload-item-icon, .upload-typ-item:hover .upload-item-title {
    color: #1D97EB;
  }

  .upload-typ-item .upload-item-title {
    color: #000;
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 5px;
  }

  .upload-typ-item .upload-item-description {
    color: #777;
    font-size: 12px;
    min-height: 51px;
  }

}

.document-list {
  display: block;

  &-search {
    display: table;
    width: 100%;
    margin-bottom: 10px;
    &-input, &-action {
      display: table-cell;
      vertical-align: top;
    }
    &-action {
      width: 120px;
      margin-right: -1px;
      button {
        border-radius: 0;
        border-right: 0 none;
        font-size: 18px;
        height: 38px;
        width: 100%;
      }
    }
    &-input input {
      border-radius: 0;
      font-size: 18px;
      height: 38px;
      box-shadow: none;
      color: #111;
      font-weight: 400;
      line-height: 15px;
    }
  }
  &-item {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #d9d9d9;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
    display: table;
    font-size: 12px;
    margin-bottom: 4px;
    width: 100%;
    padding: 4px 10px;

    &-content {
      display: table-cell;
      vertical-align: top;
      &-title {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        margin-bottom: 2px;
        font-size: 13px !important;

        .btn-link {
          padding: 0 !important;
          font-size: 13px !important;
        }
        strong {
          color: #333;
          font-weight: 600;
        }
        a {
          color: #286fac !important;
          overflow: hidden;
        }

        .fa {
          padding-right: 5px;
        }
        .fa-file-pdf-o {
          color: #E41E00;
        }

        .fa-file-word-o {
          color: #295598;
        }

        .fa-file-excel-o {
          color: #1F7246;
        }

        .fa-file-powerpoint-o {
          color: #D34826;
        }

        .fa-envelope {
          color: #0470C4;
        }
      }
      &-text {
        color: #808080;
        display: block;
        font-size: 12px;
        overflow: hidden;
        margin-bottom: 2px;
        b {
          color: #000;
          font-weight: 600;
          padding: 0 3px;
        }
      }
      &-tags {
        display: block;
        .label {
          display: inline-block;
          font-size: 90%;
          font-weight: 500;
          padding: 2px 5px;
          border: 1px solid #fff;
          margin: 4px 4px 1px 0;
          i {
            line-height: 15px;
            margin-right: 5px;
            float: left;
          }
          span {
            display: inline-block;
            line-height: 14px;
            margin: 0;
          }
        }
        .label-category {
          background: #d9edf7 !important;
          border-color: #bce8f1 !important;
          color: #31708f !important;
        }

        .label-category a {
          color: #31708f !important;
        }

        .label-filenumber {
          background-color: #fcf8e3 !important;
          border-color: #faebcc !important;
          color: #8a6d3b !important;
        }

        .label-filenumber a {
          color: #8a6d3b !important;
        }

        .label-additional {
          background-color: #dff0d8 !important;
          border-color: #d6e9c6 !important;
          color: #155724 !important;
        }

        .label-additional a {
          color: #155724 !important;
        }
      }
    }
    &-action {
      vertical-align: top;
      display: table-cell;
      width: 30px;
      text-align: right;
      a {
        font-size: 20px;
      }
    }
  }

  &-pager {
    display: block;
    margin-top: 10px;
    text-align: center;
    &-angle {
      color: #1d97eb;
      display: inline-block;
      font-size: 40px;
      height: 40px;
      text-decoration: none !important;
      vertical-align: top;
      width: 40px;
      border: none;
      background: transparent;
      i {
        vertical-align: top;
      }
      &:disabled {
        color: #bbb !important;
        cursor: not-allowed;
      }
    }
    &-info {
      color: #333;
      display: inline-block;
      font-size: 18px;
      padding: 9px 15px;
      vertical-align: top;
    }
  }
}
