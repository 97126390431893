@import "variables";

.order-modal {
  &-row {
    display: block;
  }
  &-box {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ddd;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;

    &-head {
      padding: 3px 5px;
      border-bottom: 1px solid #ddd;
      color: #fff;
      font-size: 14px !important;
    }
    &-content {
      padding: 5px;
      input {
        padding: 4px 5px;
        height: auto;
      }
      input[type='checkbox'] {
        margin: 0 5px 0 2px;
      }
    }
    &-history {
      &-item {
        display: block;
        padding: 5px;
        border-top: 1px solid #ddd;
        &-head {
          display: block;
          font-weight: bold;
        }
        &-content {
          display: block;
        }
      }
    }
    .form-group {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  &-comment {
    display: inline-block;
    vertical-align: top;
    .whitelist-item-detail-first {
      font-weight: bold;
    }
  }
}


.ordersituation {
  width: 100%;
  div {
    display: inline-block;
    margin-right: 10px;
  }
}
