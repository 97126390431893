.inline-div {
  display: inline-block;
}

.navbtn {
  outline: none !important;
  background: transparent;
  border-color: transparent;
  padding: 9px 12px;
  border-radius: 0;
  border-width: 0 1px;
  color: #333;
  &:hover {
    background: #e0e0e0;
    border-color: #d0d0d0;
  }
  &:disabled {
    color: #999;
  }
  &:active {
    background: #F6F6F6;
    border-color: #f0f0f0;
    color: #0477ea;
  }
  &:visited{
    color: #333;
  }
}

.buttonbar {
  background: #eee none repeat scroll 0 0;
  border-bottom: 1px solid #ccc;
  display: inline-block;
  margin: 0 0 10px 0;
  height: 38px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  .btn {
    @extend .navbtn;
  }
  .nav-tabs {
    border: 0;
    li {
      a {
        @extend .navbtn;
        font-size: 14px;
      }
    }
    .active {
      a {
        @extend .navbtn, :active;
        color: #0477ea !important;
      }
    }
  }
  &-view {
    @extend .buttonbar;
    display: inline;
    width: auto;
  }
}



#settings {
  position: fixed;
  right: 5px;
  top: 0;
  z-index: 1001;
  .btn {
    @extend .navbtn;
    font-size: 20px;
    padding: 4px 12px;
  }

  .dropdown-menu {
    padding: 0;
    a {
      color: #333 !important;
      font-size: 17px;
      padding: 8px 25px;
      .fa {
        margin-right: 15px;
      }
    }
  }
}

#left-panel {
  left: 0;
  top: 0;
  z-index: 800;
  position: fixed;
  width: 150px;

  #logo-header {
    position: fixed;
    left: 0;
    top: 0;
    background: #eee none repeat scroll 0 0;
    border-bottom: 1px solid #ccc;
    height: 38px;
    margin: 0;
    padding: 0 10px;
    width: 100%;
    z-index: 1000;
    #logo {
      left: 10px;
      position: fixed;
      top: 10px;
      img {
        width: 140px;
        border: 0;
      }
    }
  }

  nav ul {
    margin: 38px 0 0 0;
    padding: 0;
    li {
      list-style: none;
      a {
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;
        color: $fontcolor;
        display: block;
        width: 100%;
        padding: 5px 0 5px 7px;
        overflow-x: clip;
        :hover {
          text-decoration: none;
        }
        .icon {
          display: inline-block;
          width: 22px;
          .fa {
            display: inline;
          }
          .red_alert {
            background: none !important;
            position: relative;
            bottom: -5px;
            right: 7px;
            color: #f74c23 !important;
          }
        }
        .icon-label {
          display: inline-block;
          white-space: nowrap;
        }
      }
      a.active {
        background: #dadada;
      }
    }
    li.active {
      background: #e7e7e7;
    }
  }
}

#left-panel.tiled {
  width: 100%;
  position: static;

  nav ul li {
    float: left;
    padding: 30px 0;
    width: 200px;
    position: relative;
    text-align: center;
    font-size: 17px;
  }

  nav ul li a {
    display: block;
    font-size: 16px;
  }

  nav ul li a .icon-label {
    width: 100%;
    display: inline-block;
    line-height: 1em;
  }

  nav ul li a .icon {
    width: 100%;
    display: block;
    font-size: 39px;
    height: 39px;
    margin-bottom: 10px;
    line-height: 1em;
  }

  nav ul li a .icon .fa {
    display: inline-block;
  }

  nav ul li a .icon .fa-stack.subscript {
    height: inherit;
    line-height: inherit;
  }

  nav ul li a .icon .fa-stack.subscript.right .fa-stack-2x {
    font-size: 0.60em;
  }

  nav ul li a .icon .red_alert {
    position: absolute;
    top: 12px;
    left: 52px;
    font-size: 35px;
  }
}

#main {
  margin: 50px 0 20px 150px;
  min-height: 600px;
  padding: 0;
  #content {
    margin: 0;
  }
  .main-view {
    margin: 0 10px 10px 10px;
  }
}
