#contactcards .contactcard {
  background: url("../assets/images/customer_contact_opancy.png") no-repeat scroll 277px 6px #fdfdfd;
  border: 1px solid #B7B7B7;
  box-shadow: 0 0 5px #CCCCCC;
  cursor: pointer;
  float: left;
  height: 184px;
  line-height: 18px;
  margin: 0 10px 10px 0;
  overflow: hidden;
  padding: 10px;
  white-space: nowrap;
  width: 325px;
  color: #333333 !important;
  display: grid;
  h4 {
    font-weight: 300 !important;
    font-size: 20px !important;
    margin: 0;
    span {
      margin-right: 8px;
    }
  }
}

.customer-opos-date {
  width: 100px;
  padding-left: 5px;
}

.sales-note-box-danger {
  margin-right: 5px;
  padding: 3px;
  color: #8c8c8c;
  background-color: #f8d7da;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #f5c6cb;
  border-radius: .25rem;
  h3 {
    color: #721c24;
  }
}

.sales-note-box-warning {
  margin-right: 5px;
  padding: 3px;
  color: #8c8c8c;
  background-color: #fff3cd;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #ffeeba;
  border-radius: .25rem;
  h3 {
    color: #856404;
  }
}
