@import "variables";

.business-order-create {

  tr.frame-table-box > td {
    background-color: rgb(252, 249, 231);
  }

  tr.selected-table-box > td {
    background-color: $selected-color;
    color: $selected-font-color;
  }

  .ba-list {
    &-head {
      display: block;
      font-size: 120%;
      padding: 0 10px;
      width: 100%;
      .btn {
        padding: 0;
        background: transparent;
        border: none;
      }
      .fa-file-pdf-o {
        margin-left: 10px;
      }
    }

  }
}

.business-order-view-table {
  tr.article-child {
    td {
      text-align: center !important;
      background-color: $light-blue-bg-color;
    }
    td a {
      display: block;
      width: 100%;
      height: 100%;
      color: white !important;
    }
  }
  tr.middle-child {
    td {
      text-align: center !important;
    }
  }
  tr.red-child {
    td {
      @extend .red_alert;
    }
  }
  tr.blue-child {
    td {
      background-color: #E0E0FF !important;
      border-color: #CCCCEB !important;
      color: #4242A9 !important;
    }
  }
  tr.align-center {
    td {
      text-align: center !important;
    }
  }
  tr {
    td {
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
    }
  }
}

.label-labelprint {
  background-color: #E83E8C;
}

.btn-rose{
  background: #E83E8C;
  border-color: #d3347c;
  color: #fff;
  &:hover {
    color: #fff;
  }
  .badge {
    color: #E83E8C;
    background-color: #fff;
  }
}

.btn-rose.active {
  color: #fff;
  border-color: #d3347c;
  background-color: #d3347c;
}

.label-rose {
  @extend .label-default;
  background-color: #e83e8c !important;
}

.label-primary.active {
  background-color: #286090 !important;
  box-shadow: #141f33 1px 1px;
}

.label-purple.active {
  background-color: #4d004d !important;
  box-shadow: #330033 1px 1px;
}

.label-orange.active {
  background-color: #b35a27 !important;
  box-shadow: #331a0b 1px 1px;
}

.label-rose.active {
  background-color: #d3347c !important;
  box-shadow: #330a1f 1px 1px;
}

.label-success.active {
  background-color: #52A352 !important;
  box-shadow: #1E3D1E 1px 1px;
}

.label-danger.active {
  background-color: #AF4545 !important;
  box-shadow: #331A0B 1px 1px;
}

.label-info.active {
  background-color: #4FA9C3 !important;
  box-shadow: #141f33 1px 1px;
}

.label-gold.active {
  background-color: #B98E0E !important;
  box-shadow: #331A0B 1px 1px;
}
