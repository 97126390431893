#logo-dashboard {
  padding: 9px;
  img {
    height: 20px;
  }
}

.dashboard-spacer {
  padding: 5px;
}

.dashboard-container {
  margin-top: 30px;
  padding: 8px;
  overflow-y: visible;
}

.dashboard-filter, .dashboard-item {
  padding: 5px 5px 0 5px;
  background: #fafafa;
  border: 1px solid #ddd !important;
  margin-bottom: 5px;
  display: block;
  &-row {
    display: table;
    width: 100%;
  }
  &-cell {
    display: table-cell;
    padding-left: 7px;
    vertical-align: top;
    :last-child {
      padding-right: 0px;
    }
  }
  &-cell-status {
    width: 20px;
    position: relative;
    .label {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 5px;
      right: 0;
      font-size: 0 !important;
      padding: 0 !important;
    }
  }
  &-col {
    display: block;
    padding-bottom: 5px;
    label, input {
      display: inline-block;
      width: auto;
    }
    label {
      width: 70px;
    }
    input, .input-sm {
      padding: 2px 4px !important;
      height: auto !important;
    }
    .label-danger {
      margin-left: 5px;
    }
    .progress {
      margin: 0;
      width: 245px;
    }
  }
}

.dashboard-item {
  background: #fff;
}

.dashboard-filter-btn {
  margin: 2px;
}

.dashboard-start-btn {
  margin: 3px;
}

.dashboard-trash-button {
  padding: 3px !important;
}
