.canban {
  margin-bottom: 15px;
  .whitebox {
    width: 100%;
    margin-top: -1px;
    .form-control {
      border: 0;
      border-radius: 0;
      padding: 0 3px;
      margin: 0 10px 0 0;
      background: transparent;
      font-size: 12px;
      height: auto;
      box-shadow: none;
      border-bottom: 1px solid #ccc;
      text-align: right;
      width: (100%-10);
    }
    label {
      margin: 0;
    }
    .input-group-addon {
      background: transparent;
      border: 0;
      padding: 0;
      font-size: 12px;
    }

  }
}
