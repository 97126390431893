@import 'variables';

.login-body {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0) url("../assets/images/bg.png") no-repeat scroll 0 0 / cover;
}

.login-form {
  height: 215px;
  left: 50%;
  margin-left: -150px;
  margin-top: -107px;
  position: absolute;
  top: 50%;
  width: 300px;

}

.login-form .login-logo {
  display: block;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.login-form .login-logo img {
  width: 140px;
}

.login-input-row {
  display: table;
  width: 100%;
  margin-bottom: -1px;
}

.login-input-icon {
  display: table-cell;
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  background: #eee;
  border: 1px solid #e0e0e0;
  font-size: 20px;
  color: #666;
}

.login-input-data {
  display: table-cell;
  height: 40px;
  vertical-align: middle;
  border: 1px solid #e0e0e0;
  border-width: 1px 1px 1px 0;
  background: #fff;
}

.login-input-data input {
  border-width: 0 !important;
  background: transparent;
  box-shadow: none !important;
  margin: 0;
  padding: 0 10px;
  height: auto;
  font-size: 15px;
}

.login-input-row:first-child .login-input-icon {
  border-top-left-radius: 5px;
}

.login-input-row:first-child .login-input-data {
  border-top-right-radius: 5px;
}

.login-input-row:last-child .login-input-icon {
  border-bottom-left-radius: 5px;
}

.login-input-row:last-child .login-input-data {
  border-bottom-right-radius: 5px;
}

.login-form .login-navigation {
  display: block;
  margin-top: 10px;
}

.login-form .login-navigation .btn {
  width: 100%;
  font-size: 16px;
}

.login-form .login-navigation a {
  font-size: 13px;
}

.login-footer {
  bottom: 0;
  color: #999;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.login-body a {
  color: #777 !important;
}

.env-alert {
  border: 1px solid #aaa;
  border-radius: 5px;
  display: block;
  margin: 10px 0;
  background: #fff;
}

.env-alert .label {
  display: inline-block;
  font-size: 95%;
  font-weight: normal;
  margin: 2px 5px 0 0;
  padding: 6px 9px;
}

.env-alert-icon {
  background: #ccc none repeat scroll 0 0;
  color: #fff;
  display: table-cell;
  text-align: center;
  width: 15px;
}

.env-alert-icon i {
  padding: 0 5px;
}

.env-alert-text {
  display: table-cell;
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 400;
}

.env-alert-danger {
  border-color: #F32C1E;
}

.env-alert-danger .env-alert-icon {
  background: #F32C1E;
}

.env-alert-danger .env-alert-text {
  color: #F32C1E;
}
