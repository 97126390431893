@mixin bottom-border() {
  border-bottom: 1px solid #ddd !important;
}

.whitetable {
  .red {
    background-color: red;
  }

  tr {
    th {
      text-align: left;
      padding: 0 4px 2px 4px;
      background: transparent;
      border: 0 !important;
      font-size: 15px;
      font-weight: 300;
      &:first-child {
        padding-left: 4px !important;
      }
      &:last-child {
        padding-right: 4px !important;
      }
    }
    td {
      padding: 6px 8px;
      background: #fff;
      vertical-align: middle;
      &:first-child {
        border-left: 1px solid #ddd;
      }
      &:last-child {
        border-right: 1px solid #ddd;
      }
      a {
        color: #333 !important;
        text-decoration: none !important;
      }
      .btn-sm {
        padding: 3px 5px;
        margin: -3px -3px -3px 0;
        font-size: inherit;
      }
      .item-status {
        .label {
          margin: 0;
        }
      }
      label {
        display: block;
        margin-bottom: 1px;
        text-align: right;
      }
      .label {
        margin-left: 5px;
      }
      .table-flag {
        margin-left: 2px;
      }
      .btn-transparent {
        background: none repeat scroll 0 0 transparent;
        border: medium none;
        float: left;
        font-size: 20px;
        line-height: 20px;
        margin: 0 5px;
        padding: 4px;
      }
      input, textarea {
        background: transparent;
        border: 1px solid transparent;
        cursor: pointer;
        padding: 4px 3px;
        font-weight: normal;
        &:hover, &:focus {
          border: 1px solid #66afe9;
          border-radius: 3px;
          outline: 0 none;
          background: #eee;
        }
      }
      .small-input {
        border: 1px solid #ccc;
        padding: 3px !important;
        text-align: right;
        width: 55px;
        font-size: 13px;
        height: auto;
      }
      .input-bg {
        border: 1px solid #ccc !important;
      }
      .position_action {
        background: transparent;
        border: 0;
        margin: 3px 0;
      }
      .position_info_block {
        display: block;
        text-align: right;
        font-size: 10px;
      }
      .position_refresh {
        font-size: 19px;
        margin: 0 0 2px 0;
        padding: 0;
      }

    }
    .icon {
      width: 35px;
      .btn {
        font-size: 18px;
        margin: -5px 0 0;
        padding: 0;
        position: absolute;
      }
    }
    .icon-lg {
      width: 40px;
      position: relative;
      .fa-icon-size {
        font-size: 24px;
        left: 7px;
        margin: 0;
        padding: 0;
        position: absolute;
        top: 5px;
      }
    }

    .right {
      text-align: right !important;
    }
    .middle {
      text-align: center !important;
    }
    .bold {
      font-weight: bold !important;
    }
    &:last-child {
      td {
        @include bottom-border();
      }
    }
  }
  tr.bottom-row {
    td {
      @include bottom-border();
      border-top: 0 none !important;
      border-left: 0 none !important;
      border-right: 0 none !important;
    }
  }
  tr.filterrow {
    th {
      background: #fafafa none repeat scroll 0 0;
      border-top: 1px solid #ddd !important;
      padding: 4px;
      vertical-align: middle;
      &:first-child {
        border-left: 1px solid #ddd !important;
      }
      &:last-child {
        border-right: 1px solid #ddd !important;
      }
      input {
        @extend .form-control;
        @extend .input-sm;
        background: #fff none repeat scroll 0 0;
        border-color: #ddd;
        border-radius: 0;
        box-shadow: none;
        cursor: pointer;
        display: inline-block;
        height: auto;
        padding: 3px 5px;
        position: relative;
        &:hover {
          @extend :focus;
        }
      }
      .filterinput-sm {
        width: 80px;
      }
    }
    .cat-stars {
      min-width: 100px;
    }
  }
  tr.alert-danger {
    td {
      @extend .alert-danger;
      background: #F2DEDE none;
    }
  }
  tr.alert-warning {
    td {
      @extend .alert-warning;
      background: #fcf8e3 none;
    }
  }
  tr.selected {
    td {
      @extend .selected;
    }
  }
  tr.selectedgray {
    td {
      @extend .selectedgray;
    }
  }

  tfoot td {
    background: transparent;
    border: 0 !important;
  }
}

.whitetable-borderless {
  tr {
    &:not(.bottom-row) {
      td {
        border: 0 none !important;
      }
      &:last-child {
        td {
          border-bottom: 0 none !important;
        }
      }
    }
  }
}
