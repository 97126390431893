@import "../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";

@mixin list-item-variant($color, $background, $border) {
  color: $color;
  background-color: $background;
  border-color: $border;
}

.whitelist-borderless {
  .row.whitelist-item {
    margin-bottom: -6px;
    cursor: inherit !important;
  }
  .whitelist-item.whitelist-item-info {
    @include list-item-variant($btn-info-color, $btn-info-bg, $btn-info-border);
  }
  .whitelist-item.whitelist-item-danger {
    @include list-item-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border);
  }
  .whitelist-item.whitelist-item-success {
    @include list-item-variant($btn-success-color, $btn-success-bg, $btn-success-border);
  }
  .whitelist-item.whitelist-item-default * {
    color: lightgray !important;
  }
  .whitelist-item {
    &:first-child &:not(:last-child) {
      border-bottom: 0 !important;
    }
    &:not(:first-child) {
      border-top: 0 !important;
    }
    .pull-right {
      text-align: right !important;
    }
  }
}

.whitelist {
  &-no-cursor {
    cursor: default !important;
  }
  &-item-success {
    .whitelist-item-detail-first {
      color: $btn-success-bg;
    }
  }
  &-item-danger {
    .whitelist-item-detail-first {
      color: $btn-danger-bg;
    }
  }
  &-item-primary {
    .whitelist-item-detail-first {
      color: $btn-primary-bg;
    }
  }
  &-item {
    display: inline-block;
    background: white;
    border: 1px solid #ddd;
    margin-bottom: -1px;
    padding: 5px 8px;
    width: 100%;
    cursor: pointer;
    &-head {
      display: flex;
      margin: -5px -8px 10px;
      padding: 5px 0;
      h4 {
        margin: 0 !important;
      }
      .col-sm-6 {
        padding: 0 10px;
      }
    }
    &-detail {
      &-first {

      }
      &-second {
        font-size: 11px;
      }
      &-third {
        font-size: 11px;
        color: #909090;
      }
    }
    &-margin {
      margin-bottom: 10px;
    }
    p {
      margin: 0 !important;
      i {
        margin-right: 7px;
      }
    }
  }
  &-one-row {
    h4 {
      display: inline-block;
      font-size: 12px !important;
      font-weight: bold !important;
      margin-right: 6px !important;
    }
    p {
      display: inline-block;
      font-size: 11px !important;
      margin-right: 3px !important;
    }
  }
  &-bold-head {
    h4 {
      font-size: 12px !important;
      font-weight: bold !important;
      margin-bottom: 2px !important;
    }
  }
}
